<template>
	<v-row justify="center" class="mx-2 mt-2">
		<v-alert v-if="!transactionId" color="error" dark type="error" width="1000">
			{{ $t("global.cantOpenWithoutTx") }}
		</v-alert>
		<v-overlay v-else-if="!paymentMethods">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-alert v-else-if="!paymentMethods.length" color="error" dark type="error" width="1000">
			{{ $t("global.paymentProblemTryLater") }}
		</v-alert>
		<payments-methods-page v-else :transaction-id="transactionId" :payment-methods="paymentMethods" :promo="promo" />

		<v-dialog v-model="modal" persistent max-width="580" min-width="330">
			<v-card>
				<v-card-title class="text-h6"> {{ $t(error.msg, error.params) }}</v-card-title>
				<v-card-actions v-if="areYouSure">
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="regret">
						{{ $t("global.no") }}
					</v-btn>
					<v-btn color="green darken-1" text @click="modal = false">
						{{ $t("global.yes") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="birthYearAndChangeEmailModal" persistent max-width="580" min-width="330">
			<v-card style="padding: 1.2rem">
				<v-form @submit.prevent="submitForm" v-model="validForm">
					<v-card-text v-if="shouldChangeEmail" class="pa-0 my-5 text-left text-lg">
						<input-field required type="email" class="my-2" color="black" :label="$t('global.validEmailTitle')"
							v-model="emailInput" :props="{ styleType: 'outlined' }"
						/>
					</v-card-text>
					<v-card-text v-if="shouldChangeBirthYear" style="width: 250px;" class="pa-0 my-5 text-left text-lg">
						<input-field v-model="birthYearInput" required type="select" class="my-2" color="black" :label="$t('global.enterBirthYear')"
							:props="{items: years, styleType: 'outlined', type: 'number' }"
						/>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn type="submit" color="green darken-1" text :disabled="!validForm">
							{{ $t("global.submit") }}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import PaymentsMethodsPage from '../../components/PaymentsMethodsPage.vue';
import ApiService from '../../services/ApiService.js';
import * as chats from '../../helpers/chats';
const trialEmails = ['trial.cc'];

function isTrialEmail(email) {
  return trialEmails.some((trialEmail) => email.includes(trialEmail));
}

export default {
  components: { PaymentsMethodsPage },
  data() {
    return {
      transactionId: null,
      paymentMethods: null,
      promo: null,
      error: '',
      areYouSure: false,
      modal: false,
      birthYearAndChangeEmailModal: false,
      birthYearInput: '',
      shouldChangeBirthYear: false,
      emailInput: '',
      shouldChangeEmail: false,
      validForm: false,
      years: Array.from({ length: 121 }, (_, i) => new Date().getFullYear() - i).map((y) => ({ text: y, value: y })),
      includesFreshchat: ['apollogroup'],
      includesLivechat: ['pizzatime']

    };
  },
  methods: {
    async getPaymentMethods() {
      this.transactionId = decodeURIComponent(this.$router.currentRoute.query.id);
      if (this.transactionId) {
        const paymentMethodsRes = await ApiService.publicApi.getAvailablePaymentMethods(this.transactionId);
        if (paymentMethodsRes.email && isTrialEmail(paymentMethodsRes.email)) {
          this.shouldChangeEmail = true;
          this.birthYearAndChangeEmailModal = true;
        }

        if (paymentMethodsRes.availablePaymentMethods.length === 0) {
          this.error = { msg: this.$t('global.cantFindSub') };
        }

        if (paymentMethodsRes.profile <= 10) {
          if (!paymentMethodsRes.birthYear) {
            this.shouldChangeBirthYear = true;
            this.birthYearAndChangeEmailModal = true;
          }
        }

        if (paymentMethodsRes.profile === 1 && !this.loadedAsIframe()) {
          
          if (this.includesFreshchat.includes(paymentMethodsRes?.brand)) {
            chats.injectFreshchat();
            
            setTimeout(async () => {
              chats.initiateFreshchat(chats.maskingEmail(paymentMethodsRes.email));
            }, 5000);
          }

          if (this.includesLivechat.includes(paymentMethodsRes?.brand)) {
            chats.injectLiveChat();
          }

        }

        this.$i18n.locale = paymentMethodsRes.language;
        this.paymentMethods = paymentMethodsRes.availablePaymentMethods;
        this.error = paymentMethodsRes.error || '';
        this.areYouSure = paymentMethodsRes.areYouSure;
        this.promo = paymentMethodsRes.promo;

        if (this.error) {
          this.modal = true;
        }
      }
    },

    async submitForm() {
      try {
        await ApiService.publicApi.updateClientByTransactionId(this.transactionId, {
          birthYear: this.birthYearInput,
          email: this.emailInput
        });
        this.birthYearAndChangeEmailModal = false;
      } catch (e) {
        console.error(e);
      }
    },
    regret() {
      this.areYouSure = false;
      window.location.href = document.referrer;
    },
    loadedAsIframe() {
      return window.self !== window.top;
    }
  },
  created() {
    this.getPaymentMethods();
  }
};
</script>
