<template>
	<v-container>
		<div class="text-h3">{{ $t('global.paymentRejected') }}</div>
		<v-avatar color="#f8faf5" size="200">
			<v-icon size="100" color="red darken-2">mdi-close</v-icon>
		</v-avatar>
		<div class="body-1">{{ $t('global.paymentRejectedDetails') }}</div>
		<div style="font-size: 20px !important;font-weight: 800;">
			{{ response.msg }}
		</div>
	</v-container>
</template>

<script>
export default {
	data() {
		return {};
	},
	props: {
		response: { type: Object, required: true }
	}
};
</script>
