import superAgent from 'superagent';
import { apiRequest } from './api.js';

const BASE_URL = '/api/public';

const getAvailablePaymentMethods = (transactionId) => apiRequest({ method: 'get', endpoint: `${BASE_URL}/getAvailablePaymentMethods`, query: { transactionId } });

const updateClientByTransactionId = (transactionId, { birthYear, email }) => apiRequest({
	method: 'post',
	endpoint: `${BASE_URL}/updateClientByTransactionId`,
	body: { transactionId, birthYear, email }
});

const selectPaymentMethod = (transactionId, paymentMethodId, clientIp) => 
	apiRequest({ method: 'post', endpoint: `${BASE_URL}/selectPaymentMethod`, body: { transactionId, paymentMethodId, clientIp } });

const getStates = (countryCode) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/getStates`, body: { countryCode } });
const getTransactionStatus = (id, cryptoAddresses) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/getTransactionStatus`, body: { id, cryptoAddresses } });
const getCountries = () => apiRequest({ method: 'post', endpoint: `${BASE_URL}/getCountries`, body: {} });

const getCryptoAddress = (coin, transactionId) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/getCryptoAddress`, body: { coin, transactionId } });

// TODO: Change name function to createPaymentRequest.
const createPaymentRequest = async (transactionId, creditCardDetails) => 
	apiRequest({ method: 'post', endpoint: `${BASE_URL}/createPaymentRequest`, body: { transactionId, creditCardDetails } });

const getCurrentClientIpIpify = async () => {
	try {
		const res = await superAgent.get('https://api.ipify.org/?format=json').catch(() => null);
		return res.body.ip;
	} catch {
		return null;
	}
};

const getCurrentClientIpCloudflare = async () => {
	const res = await superAgent.get('https://www.cloudflare.com/cdn-cgi/trace');
	const values = _(res.text)
		.split('\n')
		.transform((object, string) => {
			const [key, value] = _.split(string, '=');
			if (!(key && value)) return;
			object[key] = value;
		}, {})
		.value();

	return values.ip;
};

const applyPromoCode = async (transactionId, promoCode) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/applyPromoCode`, body: { transactionId, promoCode } });

export default {
	getAvailablePaymentMethods,
	selectPaymentMethod,
	createPaymentRequest,
	getStates,
	getCountries,
	getCurrentClientIpIpify,
	getCurrentClientIpCloudflare,
	getTransactionStatus,
	applyPromoCode,
	updateClientByTransactionId,
	getCryptoAddress
};
