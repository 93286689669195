<template>
	<v-container :class="$style.container">
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>

		<v-card-title elevation="2">
			{{ packageName }} ({{ amount }} {{ currency }})
			{{ promoName ? `Promo ${promoName} applied` : '' }}
			<img v-if="mSite" style="width: 100px;" :src="paymentMethodLogo" />
		</v-card-title>
		<v-divider></v-divider>

		<v-row v-if="cardDescription">
			<v-col>
				<p :class="$style.cardDescriptionStyle">
					{{ `${$t('global.importantCardDescriptor')} "${cardDescription}".` }}
				</p>
			</v-col>
		</v-row>

		<v-form v-model="validForm">
			<v-row>
				<v-col>
					<input-field
						:props="{ items: countriesList, styleType: 'outlined', autocomplete: 'off' }"
						v-model="clientCountryCode"
						required
						:label="$t('global.country')"
						type="select"
						@blur="onCountryChange"
					></input-field>
				</v-col>

				<v-col v-for="missingField in missingParamsObj.params" :key="missingField">
					<input-field
						v-if="missingField === 'state' && statesList.length > 1"
						:props="{ items: statesList, styleType: 'outlined', autocomplete: 'off' }"
						v-model="missingInfoClientInput[missingField]"
						required
						:label="missingField"
						type="select"
					></input-field>
					<input-field
						v-else-if="missingField !== 'state'"
						:props="{ styleType: 'outlined', autocomplete: 'off' }"
						v-model="missingInfoClientInput[missingField]"
						required
						:label="missingField"
						type="special-text"
					></input-field>
				</v-col>
				<v-col v-if="mSite">
					<input-field
						:props="{ styleType: 'outlined', autocomplete: 'off' }"
						v-model="mSiteEmail"
						required
						:label="$t('global.email')"
						type="special-text"
					></input-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<input-field
						required
						clearable
						:mask="cardNumberMask"
						:label="$t('global.cardNumber')"
						v-model="cardNumber"
						type="credit-card"
						:props="{ styleType: 'outlined', autocomplete: 'off' }"
					/>
				</v-col>
			</v-row>
			<v-row v-if="cardInvalid" justify="space-around" class="mt-5">
				<p :class="$style.cardDescriptionStyle">{{ cardInvalid }}</p>
			</v-row>
			<v-row>
				<v-col>
					<input-field
						required
						clearable
						:label="$t('global.cardOwnerFirst')"
						v-model="cardOwnerFirst"
						type="text"
						:props="{ styleType: 'outlined', autocomplete: 'off' }"
					/>
				</v-col>
				<v-col>
					<input-field
						required
						clearable
						:label="$t('global.cardOwnerLast')"
						v-model="cardOwnerLast"
						type="text"
						:props="{ styleType: 'outlined', autocomplete: 'off' }"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<input-field
						required
						clearable
						:props="{ items: months, styleType: 'outlined', autocomplete: 'off' }"
						v-model="cardMonth"
						:label="$t('global.month')"
						type="select"
					/>
				</v-col>
				<v-col>
					<input-field
						required
						clearable
						:props="{ items: years, styleType: 'outlined', autocomplete: 'off' }"
						v-model="cardYear"
						:label="$t('global.year')"
						type="select"
					/>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col md="5">
					<input-field
						required
						clearable
						:label="$t('global.cvv')"
						type="number"
						:value="cvv"
						v-model="cvv"
						mask="####"
						:props="{ styleType: 'outlined', autocomplete: 'off' }"
					/>
				</v-col>
			</v-row>
			<v-row justify="space-around" class="mt-5">
				<input-field
					required
					type="boolean"
					v-model="agree"
					:label="
						mSite
							? `By checking the box and clicking continue, you confirm that you acknowledge the terms
							presented in the Terms of Services, Privacy Policy and Return policy. You also certify
					that you are 18 years of age or older. Today's charge will be ${amount} ${currency} for ${packageName}.
					Be aware that this is a ONE time charge. In order to renew your package, you will need to do this process again.`
							: `${$t('global.paymentDiscloser')} ${amount} ${currency}`
					"
					:props="{ styleType: 'outlined', autocomplete: 'off' }"
				/>
			</v-row>

			<v-row justify="space-around" class="mt-5">
				<p style="max-width: 150px;" :class="$style.cardDescriptionStyle">{{ `${$t('global.payTo')} ${cardDescription}` }}</p>
				<v-btn text color="primary" @click="onSubmit" :disabled="!validForm || !agree">
					{{ $t('global.submit') }}
					<v-icon right>mdi-check</v-icon>
				</v-btn>
			</v-row>
			<img v-if="mSite" style="width: 100px;" :src="paymentMethodLogo" />
		</v-form>
	</v-container>
</template>

<script>
import Luhn from 'luhn-js';
import ApiService from '../../services/ApiService.js';
import VueService from '../../services/VueService.js';
const { _, moment } = VueService;

export default {
	data() {
		return {
			cardNumber: '',
			cardMonth: '',
			cardYear: '',
			cardOwnerLast: '',
			cardOwnerFirst: '',
			cvv: '',
			validForm: false,
			loading: false,
			agree: false,
			missingInfoClientInput: {},
			statesList: [],
			countriesList: [],
			clientCountryCode: '',
			mSiteEmail: '',
			cardInvalid: null
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		mSite: { type: Boolean, required: true },
		walletId: { type: String, required: false, default: null },
		pspType: { type: String, required: true },
		missingParamsObj: { type: Object, default: () => {} },
		countryCode: { type: String, default: '' },
		packageName: { type: String, default: '' },
		cardDescription: { type: String, default: '' },
		paymentMethodLogo: { type: String, default: '' },
		promoName: { type: String, default: '' }
	},
	computed: {
		cardType() {
			const number = this.cardNumber;
			switch (true) {
				case /^4/.test(number):
					return 'visa';
				case /^(34|37)/.test(number):
					return 'amex';
				case /^5[1-5]/.test(number):
					return 'mastercard';
				case /^6011]/.test(number):
					return 'discover';
				case /^9792/.test(number):
					return 'troy';
				default:
					return 'visa';
			}
		},
		cardNumberMask() {
			return this.cardType === 'amex' ? '#### ###### #####' : '#### #### #### ####';
		},
		minCardMonth() {
			return this.cardYear === moment().year() ? moment().month() + 1 : 1;
		},
		months() {
			const allMonths = _.range(1, 13);
			return _.filter(allMonths, (month) => this.minCardMonth <= month);
		},
		years() {
			const currentYear = moment().year();
			return _.range(currentYear, currentYear + 20);
		}
	},
	watch: {
		cardYear() {
			if (this.cardMonth < this.minCardMonth) {
				this.cardMonth = '';
			}
		}
	},
	methods: {
		async onSubmit() {
			this.loading = true;
			try {
				const cardNumber = this.cardNumber.replace(/[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000 ]/g, '');
				const isCardvalid = Luhn.isValid(cardNumber);

				if (!isCardvalid) {
					this.cardInvalid = 'Invalid card';
					this.loading = false;
					return;
				}

				const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
					cardNumber: cardNumber,
					cardMonth: this.cardMonth,
					cardYear: this.cardYear,
					cardOwnerFirst: this.cardOwnerFirst,
					cardOwnerLast: this.cardOwnerLast,
					cvv: this.cvv,
					missingInfoClientInput: this.missingInfoClientInput,
					country: this.clientCountryCode
				});
				this.$emit('payment-processed', { pspType: this.pspType, response, cardDescription: this.cardDescription });
				this.loading = false;
			} catch (err) {
				this.loading = false;
				this.$emit('payment-rejected', { msg: err?.response?.body?.message });
			}
		},
		async onCountryChange() {
			if (_.includes(this.missingParamsObj.params, 'state') && this.clientCountryCode) {
				const statesRes = await ApiService.publicApi.getStates(this.clientCountryCode);

				if (_.size(statesRes.states) > 0) {
					const displayList = _(statesRes.states)
						.map((state) => ({ text: state.name, value: state.code }))
						.sortBy((displayItem) => displayItem.text)
						.value();

					this.statesList = displayList;
					this.missingInfoClientInput.state = null;
				} else {
					this.statesList = [{ text: 'None', value: 'Other' }];
					this.missingInfoClientInput.state = 'Other';
				}
			}
		}
	},
	async created() {
		this.clientCountryCode = this.countryCode;
		const countriesRes = await ApiService.publicApi.getCountries();

		let mappedCountries = _(countriesRes)
			.map((state) => ({ text: state.name, value: state.code }))
			.sortBy((displayItem) => displayItem.text)
			.value();

		if (this.mSite) {
			const restrictedCountries = [
				'Belarus',
				'Central African Republic',
				'Congo (Brazzaville)',
				'Congo (Kinshasa)',
				'Cuba',
				'Iran',
				'Iraq',
				'Lebanon',
				'Libya',
				'North Korea',
				'Somalia',
				'South Sudan',
				'Syria',
				'Venezuela',
				'Yemen',
				'Zimbabwe',
				'Sudan',
				'Ivory Coast',
				'Myanmar',
				'Liberia',
				'Russia',
				'Ukraine',
				'Hong Kong',
				'Afghanistan',
				'Ethiopia',
				'Mali',
				'Nicaragua'
			];
			mappedCountries = _.omitBy(mappedCountries, (country) => _.includes(restrictedCountries, country.text));
		}

		this.countriesList = mappedCountries;

		if (_.includes(this.missingParamsObj.params, 'state') && this.countryCode) {
			const statesRes = await ApiService.publicApi.getStates(this.countryCode);
			if (_.size(statesRes.states) > 0) {
				const displayList = _(statesRes.states)
					.map((state) => ({ text: state.name, value: state.code }))
					.sortBy((displayItem) => displayItem.text)
					.value();

				this.statesList = displayList;
			} else {
				this.statesList = [{ text: 'None', value: 'Other' }];
				this.missingInfoClientInput.state = 'Other';
			}
		}
	}
};
</script>

<style scoped>
.col {
	min-width: 220px !important;
}
</style>
<style module>
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap');

.container {
	max-width: 570px;
	margin: auto;
	width: 100%;
}

.roundedCard {
	border-radius: 20px !important;
	margin: auto;
}

.creditCardFont {
	font-family: 'Source Code Pro', monospace;
	font-weight: 500;
	width: 16px;
	line-height: 1;
	color: #ffffff;
	font-size: 27px;
	margin-bottom: 35px;
	padding: 10px 15px;
	text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
}

.cardLabel {
	color: #ffffff;
	font-family: 'Source Code Pro', monospace;
	opacity: 0.7;
	font-size: 13px;
	margin-bottom: 6px;
	text-transform: none;
}

.cardText {
	font-size: 18px;
	line-height: 1;
	white-space: nowrap;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
	color: white;
}

.cardDescriptionStyle {
	font-size: 20px;
	color: red;
}
</style>
