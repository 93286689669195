<template>
	<v-dialog :value="true" persistent min-width="500" max-width="800">
		<v-card style="background-color: ghostwhite;">
			<v-card-title class="pa-0 ma-0 justify-end">
				<v-btn icon large class="mr-2" @click="$emit('cancel-payment-method')">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-3" />
			<v-card-text>
				<component
					:is="component"
					v-bind="paymentPageDetails"
					:response="response"
					@payment-processed="onPaymentProcessed"
					@payment-rejected="onPaymentRejected"
					@payment-error="onPaymentError"
				/>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	components: {
		CreditCard: () => import('./PaymentTypes/CreditCard.vue'),
		Paypal: () => import('./PaymentTypes/Paypal.vue'),
		AccentPay: () => import('./PaymentTypes/AccentPay.vue'),
		IframeDynamic: () => import('./PaymentTypes/IframeDynamic.vue'),
		PaymentProcessed: () => import('./PaymentResponses/PaymentProcessed.vue'),
		PaymentRejected: () => import('./PaymentResponses/PaymentRejected.vue'),
		StaticInfo: () => import('./PaymentTypes/StaticInfo.vue'),
		Stripe: () => import('./PaymentTypes/Stripe.vue'),
		Square: () => import('./PaymentTypes/Square.vue'),
		Gourl: () => import('./PaymentTypes/Gourl.vue'),
		Bcoin: () => import('./PaymentTypes/Bcoin.vue'),
		Crypto: () => import('./PaymentTypes/Crypto.vue'),
		JccGateway: () => import('./PaymentTypes/JccGateway.vue')
	},
	data() {
		return {
			component: null,
			paymentProcessed: false,
			response: null
		};
	},
	props: {
		paymentPageDetails: { type: Object, required: true }
	},
	methods: {
		getPaymentType() {
			if (this.paymentPageDetails.pspType === 'Paypal') {
				return 'Paypal';
			}
			if (this.paymentPageDetails.pspType === 'AccentPay') {
				return 'AccentPay';
			}
			if (this.paymentPageDetails.pspType === 'StaticInfo') {
				return 'StaticInfo';
			}
			if (this.paymentPageDetails.pspType === 'Stripe') {
				return 'Stripe';
			}
			if (this.paymentPageDetails.pspType === 'Square') {
				return 'Square';
			}
			if (this.paymentPageDetails.pspType === 'Gourl') {
				return 'Gourl';
			}
			if (this.paymentPageDetails.pspType === 'Bcoin') {
				return 'Bcoin';
			}
			if (this.paymentPageDetails.pspType === 'Crypto') {
				return 'Crypto';
			}
			if (this.paymentPageDetails.pspType === 'JccGateway') {
				return 'JccGateway';
			}
			return 'CreditCard';
		},
		onPaymentProcessed({ pspType, response }) {
			this.response = response;

			if (response.html) {
				this.component = 'IframeDynamic';
				return;
			}

			this.paymentProcessed = true;

			this.component = 'PaymentProcessed';
		},
		onPaymentRejected(details) {
			this.response = details;
			this.component = 'PaymentRejected';
		},
		onPaymentError(error) {
			if (process.env.NODE_ENV === 'development') console.log(error);
		}
	},
	mounted() {
		this.component = this.getPaymentType();
	}
};
</script>
