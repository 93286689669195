<template>
	<component v-if="component" :is="component" :response="response"></component>
	<div v-else>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-card-title elevation="2" style="padding: 0px;">{{ $t('global.subscription') }}: {{ packageName }} ({{ amount }} {{ currency }})</v-card-title>
		<v-divider></v-divider>
		
		<v-container v-if="!error">
			<v-container>
				<v-row align="center" justify="center" style="background-color: ghostwhite" mt="1">
					<v-col cols="2" md="4">
						<v-select
							:label="$t('global.chooseCrypto')"
							:items="availableCoins"
							v-model="selectedCrypto"
							item-text="name"
							item-value="id"
							@change="onCryptoCoinSelected"
						></v-select>
					</v-col>
				</v-row>
				
				<p v-if="selectedCrypto === 'USDT_POLYGON'" style="color: red">
					<b style="font-size:20px; color: gray;">{{ $t('global.important') }}:</b> {{ $t('global.ensure') }}
				</p>

				<v-container v-if="addressInfo.length">
					<v-row align="center" justify="center">
						<h4>
							{{ $t('global.send') }} <label id="amount">{{ cryptoAmount }}</label> {{ addressInfo[0].coinDisplayName }} <span style="color: red;font-weight: 800;">{{ $t('global.inOnePayment') }} </span><br />
						</h4>
					</v-row>
					<br />

					<v-row align="center" justify="center">
						<v-col style="max-width: 500px;">
							<v-btn class="my-2" color="primary" @click="copyAmount('amount')">
								<v-icon left>mdi-content-save</v-icon>
								{{ $t('global.copyAmount') }}
							</v-btn>
						</v-col>
					</v-row>
				
					<v-container>
						<h3>{{ $t('global.chooseAnyAddress') }}</h3>
						<v-row>
							<v-col v-for="(addressData, index) in addressInfo" :key="addressData.address" class="ma-0">
								<img :src="addressData.qrCode" class="my-9" />
								<v-card elevation="2" class="pa-4 d-flex flex-column justify-space-around">
									<div>
										<p class="body-2 black--text mb-5"><span style="color: red;font-weight: 800;">{{ addressData.coinDisplayName }} {{ addressData.note || addressData.network }} </span> {{ $t('global.walletAddress') }}:</p>
										<v-row :class="[index === 0 ? 'd-flex justify-center align-center' : '']">
											<v-text-field
												solo
												dense
												readonly
												:value="addressData.address"
												:ref="index"
												append-icon="mdi-content-copy"
												@click:append="copyHandler(index)"
											></v-text-field>
										</v-row>
									</div>
								</v-card>
							</v-col>
						</v-row>
						<v-col>
							<v-checkbox
								style="font-weight: 800;"
								:label="$t('global.agreeToOneTime')"
								v-if="!getStatusInterval && !loading"
								@change="fetchForTxStatus"
							></v-checkbox>

							<template v-if="getStatusInterval">
								<p>{{ $t('global.waitingForTransfer') }}</p>
								<v-progress-circular indeterminate size="50" />
							</template>
						</v-col>
						<p v-if="selectedCrypto !== 'USDT_POLYGON'">
							<b style="font-size:20px;">{{ $t('global.pleaseNote') }}:</b> {{ $t('global.cryptoFeeNotice') }}
						</p>
					</v-container>
				</v-container>
			</v-container>
		</v-container>
		<v-container v-else>
			<h3 style="color:red">{{ $t(`global.${error}`) }}</h3>
		</v-container>
		
		<v-divider></v-divider>
		<br />
		<div class="d-flex align-items-center justify-center flex-column" v-if="staticInfo" v-html="staticInfo"></div>
	</div>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	components: {
		PaymentProcessed: () => import('../PaymentResponses/PaymentProcessed.vue'),
		PaymentRejected: () => import('../PaymentResponses/PaymentRejected.vue')
	},
	data() {
		return {
			loading: false,
			component: null,
			response: {},
			addressInfo: [],
			cryptoAmount: 0,
			selectedCrypto: null,
			getStatusInterval: null
		};
	},
	props: {
		error: { type: String, default: null },
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		packageName: { type: String, default: '' },
		staticInfo: { type: String, default: '' },
		availableCoins: { type: Array, required: true }
	},
	methods: {
		copyAmount(elementId) {
			const range = document.createRange();
			range.selectNode(document.getElementById(elementId));
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);

			document.execCommand('copy');
			window.getSelection().removeAllRanges();
		},
		async onCryptoCoinSelected() {
			this.loading = true;
			clearInterval(this.getStatusInterval);
			this.getStatusInterval = null;
			const res = await ApiService.publicApi.getCryptoAddress(this.selectedCrypto, this.transactionId);

			this.addressInfo = res.addresses;
			this.cryptoAmount = res.cryptoAmount;
			this.loading = false;
		},
		copyHandler(refName) {
			const element = this.$refs[refName][0].$el.querySelector('input');
			element.select();
			document.execCommand('copy');
			this.snackbar = true;
		},
		fetchForTxStatus() {
			this.getStatusInterval = setInterval(async () => {
				const txDetails = await ApiService.publicApi.getTransactionStatus(this.transactionId, this.addressInfo.map((add) => add.address));
				if (txDetails.status === 'Pending') {
					clearInterval(this.getStatusInterval);
					this.response.msg = txDetails.note;
					this.component = 'PaymentRejected';
				}
				if (txDetails.status === 'Approved') {
					clearInterval(this.getStatusInterval);
					this.component = 'PaymentProcessed';
				}
			}, 5000);
		}
	},
	created() {}
};
</script>
