<template>
	<div v-html="staticInfo || response"></div>
</template>

<script>
export default {
	props: {
		transactionId: { type: String, required: true },
		staticInfo: { type: String, default: null },
		response: { type: String, default: null }
	}
};
</script>

<style></style>
