<template>
	<v-container>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<div>
			<form id="form">
				<p>{{ $t('global.pageLoadNewTab') }}</p>
				<button @click="newWindow">{{ $t('global.ifNotClick') }}</button>
			</form>
		</div>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			loading: false
		};
	},
	props: {
		endPoint: { type: String, required: true },
		pspType: { type: String, required: true },
		transactionId: { type: String, required: true },
		version: { type: String, required: true },
		merId: { type: String, required: true },
		acqId: { type: Number, required: true },
		merRespUrl: { type: String, required: true },
		merCallbackUrl: { type: String, required: true },
		purchaseAmt: { type: String, required: true },
		purchaseCurrency: { type: String, required: true },
		purchaseCurrencyExponent: { type: String, required: true },
		orderId: { type: String, required: true },
		captureFlag: { type: String, required: true },
		signature: { type: String, required: true },
		signatureMethod: { type: String, required: true },
		trxType: { type: String, required: true }
	},
	methods: {
		generatePayForm() {
			this.loading = true;
			const form = document.getElementById('form');
			form.method = 'POST';
			form.action = this.endPoint;

			const versionFormField = document.createElement('input');
			versionFormField.value = this.version;
			versionFormField.name = 'Version';
			versionFormField.style = 'display:none;';
			form.appendChild(versionFormField);

			const merIdFormField = document.createElement('input');
			merIdFormField.value = this.merId;
			merIdFormField.name = 'MerID';
			merIdFormField.style = 'display:none;';
			form.appendChild(merIdFormField);

			const acqIdFormField = document.createElement('input');
			acqIdFormField.value = this.acqId;
			acqIdFormField.name = 'AcqID';
			acqIdFormField.style = 'display:none;';
			form.appendChild(acqIdFormField);

			const merRespUrlFormField = document.createElement('input');
			merRespUrlFormField.value = this.merRespUrl;
			merRespUrlFormField.name = 'MerRespURL';
			merRespUrlFormField.style = 'display:none;';
			form.appendChild(merRespUrlFormField);

			const merCallbackUrlFormField = document.createElement('input');
			merCallbackUrlFormField.value = this.merCallbackUrl;
			merCallbackUrlFormField.name = 'MerCallbackURL';
			merCallbackUrlFormField.style = 'display:none;';
			form.appendChild(merCallbackUrlFormField);

			const purchaseAmtFormField = document.createElement('input');
			purchaseAmtFormField.value = this.purchaseAmt;
			purchaseAmtFormField.name = 'PurchaseAmt';
			purchaseAmtFormField.style = 'display:none;';
			form.appendChild(purchaseAmtFormField);

			const purchaseCurrencyFormField = document.createElement('input');
			purchaseCurrencyFormField.value = this.purchaseCurrency;
			purchaseCurrencyFormField.name = 'PurchaseCurrency';
			purchaseCurrencyFormField.style = 'display:none;';
			form.appendChild(purchaseCurrencyFormField);

			const purchCurrencyExpFormField = document.createElement('input');
			purchCurrencyExpFormField.value = this.purchaseCurrencyExponent;
			purchCurrencyExpFormField.name = 'PurchaseCurrencyExponent';
			purchCurrencyExpFormField.style = 'display:none;';
			form.appendChild(purchCurrencyExpFormField);

			const orderIdFormField = document.createElement('input');
			orderIdFormField.value = this.orderId;
			orderIdFormField.name = 'OrderID';
			orderIdFormField.style = 'display:none;';
			form.appendChild(orderIdFormField);

			const captFlgFrmField = document.createElement('input');
			captFlgFrmField.value = this.captureFlag;
			captFlgFrmField.name = 'CaptureFlag';
			captFlgFrmField.style = 'display:none;';
			form.appendChild(captFlgFrmField);

			const signFormField = document.createElement('input');
			signFormField.value = this.signature;
			signFormField.name = 'Signature';
			signFormField.style = 'display:none;';
			form.appendChild(signFormField);

			const sigMthdFormField = document.createElement('input');
			sigMthdFormField.value = this.signatureMethod;
			sigMthdFormField.name = 'SignatureMethod';
			sigMthdFormField.style = 'display:none;';
			form.appendChild(sigMthdFormField);

			this.loading = false;
			form.submit();
		},
		newWindow() {
			this.loading = true;
			const form = document.getElementById('form');

			form.target = '_blank';
			this.loading = false;
			form.submit();
		}
	},
	mounted() {
		this.generatePayForm();
	}
};
</script>

<style module>
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap');

.container {
	max-width: 770px;
	margin: auto;
	width: 100%;
}
</style>
