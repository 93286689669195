<template>
	<div>
		<div :class="$style.squareForm">
			<v-overlay v-if="loading">
				<v-progress-circular indeterminate size="50" />
			</v-overlay>
			<form id="payment-form" v-if="showPaymentForm">
				<div id="card-container"></div>
				<button style="position: relative;" type="button" @click="handlePaymentMethodSubmission">{{ amount }} {{ currency }}</button>
			</form>
			<div>{{ paymentStatus }}</div>
		</div>
		<v-row v-if="cardDescription">
			<v-col>
				<p :class="$style.cardDescriptionStyle">
					{{ `${$t('global.importantCardDescriptor')} "${cardDescription}".` }}
				</p>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			loading: true,
			card: null,
			showPaymentForm: true,
			paymentStatus: ''
		};
	},
	props: {
		packageName: { type: String, default: '' },
		transactionId: { type: String, required: true },
		appId: { type: String, required: true },
		locationId: { type: String, required: true },
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		missingInfoClientInput: { type: Object, default: () => {} },
		cardDescription: { type: String, default: '' }
	},
	methods: {
		async init() {
			if (!window.Square) {
				throw new Error('Square failed to load properly');
			}

			let payments;
			try {
				payments = window.Square.payments(this.appId, this.locationId);
			} catch {
				return;
			}

			try {
				this.card = await payments.card();
				await this.card.attach('#card-container');
				this.loading = false;
			} catch (e) {
				console.error('Initializing Card failed', e);
			}
		},
		async tokenize(paymentMethod) {
			const tokenResult = await paymentMethod.tokenize();
			if (tokenResult.status === 'OK') {
				return tokenResult.token;
			}

			let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
			if (tokenResult.errors) {
				errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
			}

			throw new Error(errorMessage);
		},
		async createPayment(token) {
			this.loading = true;
			const paymentResponse = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				locationId: this.locationId,
				sourceId: token,
				missingInfoClientInput: this.missingInfoClientInput
			});
			this.loading = false;
			this.paymentStatus = paymentResponse.status;
			this.showPaymentForm = false;
		},
		async handlePaymentMethodSubmission() {
			try {
				const token = await this.tokenize(this.card);
				await this.createPayment(token);
			} catch (e) {
				console.error(e.message);
			}
		},
		loadSquarePaymentForm() {
			const script = document.createElement('script');
			script.src = 'https://web.squarecdn.com/v1/square.js';
			document.body.appendChild(script);
		}
	},
	created() {
		this.loadSquarePaymentForm();
		setTimeout(this.init, 2000);
	}
};
</script>

<style module>
.squareForm * {
	font-family: Inter, Open Sans, Segoe UI, sans-serif;
	font-size: 16px;
	font-weight: 500;
}

.squareForm form {
	max-width: 496px;
	padding: 0 15px;
	margin: 0 auto;
}

.squareForm form > * + * {
	margin-top: 20px;
}

.squareForm .container {
	background-color: #fff;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	padding: 3px;
}

.squareForm fieldset {
	border-style: none;
	padding: 5px;
	margin-left: -5px;
	margin-right: -5px;
	background: rgba(18, 91, 152, 0.05);
	border-radius: 8px;
}

.squareForm fieldset legend {
	float: left;
	width: 100%;
	text-align: center;
	font-size: 13px;
	color: #8898aa;
	padding: 3px 10px 7px;
}

.squareForm .cardOnly {
	display: block;
}

.squareForm fieldset legend + * {
	clear: both;
}

.squareForm input,
.squareForm button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	border-style: none;
	color: #fff;
}

.squareForm input:-webkit-autofill {
	transition: background-color 100000000s;
}

.squareForm #card {
	padding: 10px;
	margin-bottom: 2px;
}

.squareForm input::-webkit-input-placeholder {
	color: #9bacc8;
}

.squareForm input::-moz-placeholder {
	color: #9bacc8;
}

.squareForm input:-ms-input-placeholder {
	color: #9bacc8;
}

.squareForm button {
	display: block;
	width: 100%;
	height: 37px;
	background-color: #1976d2;
	border-radius: 2px;
	color: #fff;
	cursor: pointer;
}

.squareForm button:active {
	background-color: #1976d2;
}

.squareForm .errorCard .message {
	color: #e25950;
}

.errorCard {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;
	padding: 0 15px;
	font-size: 13px;
	opacity: 0;
	transform: translateY(10px);
	transition-property: opacity, transform;
	transition-duration: 0.35s;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.errorCard.visible {
	opacity: 1;
	transform: none;
}

.cardDescriptionStyle {
	font-size: 20px;
	color: red;
}
</style>
