<template>
	<div>
		<iframe v-if="response.url" :src="response.url"></iframe>
		<iframe v-if="response.html" :srcdoc="response.html"></iframe>
	</div>
</template>

<script>
export default {
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		response: { type: Object, required: true }
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
</style>
