import superAgent from 'superagent';
import _ from 'lodash';
import VueService from '../services/VueService.js';

const defaultApiConfig = { timeout: 20000, headers: { 'Content-Type': 'application/json;charset=UTF-8' } };

const apiRequest = async ({ method, endpoint, query, body, attachments, headers, timeout, binary = false }) => {
	try {
		const httpMethod = _.toLower(method);
		const host = window.location.hostname;
		let finalHost = process.env.VUE_APP_HTTP_DOMAIN ?? `https://public.${host}`;

		if (_.includes(host, 'payments') && !_.includes(host, 'testing')) {
			finalHost = `https://public.${_.replace(host, 'payments.', '')}`;
		}

		const apiEndpoint = `${finalHost}${endpoint}`;
		const request = superAgent[httpMethod](apiEndpoint)
			.set(headers ?? defaultApiConfig.headers)
			// .set('authorization', `Bearer ${VueService.store.state.user?.tokenSet?.access_token}`)
			.timeout(timeout ?? defaultApiConfig.timeout)
			.query(query);

		if (_.size(attachments) > 0) {
			_.each(attachments, (attachment) => request.attach(attachment.name, attachment.file));
			_.each(body, (value, key) => request.field(key, value));
		} else {
			request.send(body);
		}

		if (binary) {
			request.responseType('blob');
			request.buffer(true);
			const response = await request;
			return response;
		}
		return (await request).body.data;
	} catch (error) {
		console.error(error);
		VueService.showToastMessage({ message: error?.response?.body?.message ?? error, type: 'error' });
		// if (error?.errno === 'ETIME') {
		// 	apiRequest({ method: 'post', endpoint: '/api/public/logError', body: { error, path: endpoint } });
		// }
		// if (error.status === 401) {
		// 	VueService.store.state.user.isSessionExpired = true;
		// }
		throw error.status === 503 ? new Error('Failed duo to a problem connecting to the server.') : error;
	}
};

export { apiRequest };
