const injectFreshchat = () => {
	const e = document.createElement('script');
	e.id = 'Freshdesk Messaging-js-sdk';
	e.async = !0;
	e.src = 'https://wchat.eu.freshchat.com/js/widget.js';
	document.head.appendChild(e);
};

const initiateFreshchat = (email) => {
	window.fcWidget.init({
		token: '6c7d603f-46bf-486b-b3d2-1cb7de762472',
		host: 'https://wchat.eu.freshchat.com'
	});

	if (window.fcWidget.isOpen() !== true) {
		window.fcWidget.open({ id: 174778, replyText: email });
	}
};
/* eslint-disable */

const injectLiveChat = () => {
	(function(n, t, c) {
		function i(n) {
			return e._h ? e._h.apply(null, n) : e._q.push(n);
		}
		var e = {
			_q: [],
			_h: null,
			_v: '2.0',
			on: function() {
				i(['on', c.call(arguments)]);
			},
			once: function() {
				i(['once', c.call(arguments)]);
			},
			off: function() {
				i(['off', c.call(arguments)]);
			},
			get: function() {
				if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
				return i(['get', c.call(arguments)]);
			},
			call: function() {
				i(['call', c.call(arguments)]);
			},
			init: function() {
				var n = t.createElement('script');
				(n.async = !0), (n.type = 'text/javascript'), (n.src = 'https://cdn.livechatinc.com/tracking.js'), t.head.appendChild(n);
			}
		};
		window.__lc = window.__lc || {};
		window.__lc.license = 14964534;
		!n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
	})(window, document, [].slice);
};

const maskingEmail = (email) => {
	const splitted = email.split('@');
	let part1 = splitted[0];
	const avg = part1.length / 2;
	part1 = part1.substring(0, part1.length - avg);
	const part2 = splitted[1].substring(splitted[1].length - 5, splitted[1].length);
	return `${part1}***${part2}`;
};

export { injectFreshchat, injectLiveChat, initiateFreshchat, maskingEmail };
