<template>
	<component v-if="component" :is="component" :response="response"></component>
	<div v-else>
		<v-card-title elevation="2" style="padding: 0px;">{{ $t('global.subscription') }}: {{ packageName }} ({{ amount }} {{ currency }})</v-card-title>
		<v-divider></v-divider>
		<p>
			<b style="font-size:20px">{{ $t('global.pleaseNote') }}</b> {{ $t('global.cryptoFeeNotice') }}
		</p>
		<br />
		<v-container v-if="!error">
			<v-container>
				<v-row align="center" justify="center">
					<h4>
						{{ $t('global.send') }} <label id="amount">{{ btcAmount }}</label> BTC {{ $t('global.inOnePayment') }} <br />
					</h4>
				</v-row>
				<br />

				<v-row align="center" justify="center">
					<v-col style="max-width: 500px;">
						<v-btn class="my-2" color="primary" @click="copyAmount('amount')">
							<v-icon left>mdi-content-save</v-icon>
							{{ $t('global.copyAmount') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
			<v-container>
				<h3>{{ $t('global.chooseAnyAddress') }}</h3>
				<v-row>
					<v-col v-for="(addressData, index) in addressInfo" :key="addressData.address" class="ma-0">
						<img :src="addressData.qrCode" class="my-9" />
						<v-card elevation="2" class="pa-4 d-flex flex-column justify-space-around">
							<div>
								<p class="body-2 black--text mb-5">{{ addressData.type }} {{ $t('global.walletAddress') }}:</p>
								<v-row :class="[index === 0 ? 'd-flex justify-center align-center' : '']">
									<v-text-field
										solo
										dense
										readonly
										:value="addressData.address"
										:ref="index"
										append-icon="mdi-content-copy"
										@click:append="copyHandler(index)"
									></v-text-field>
								</v-row>
							</div>
						</v-card>
					</v-col>
				</v-row>
				<v-col>
					<p>{{ $t('global.waitingForTransfer') }}</p>
					<v-progress-circular indeterminate size="50" />
				</v-col>
			</v-container>
		</v-container>
		<v-container v-else>
			<h3 style="color:red">{{ $t(`global.${error}`) }}</h3>
		</v-container>
		<br />
		<h4>{{ $t('global.btcWarning') }}</h4>
		<br />
		<v-divider></v-divider>
		<br />
		<div class="d-flex align-items-center justify-center flex-column" v-if="staticInfo" v-html="staticInfo"></div>
	</div>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	components: {
		PaymentProcessed: () => import('../PaymentResponses/PaymentProcessed.vue'),
		PaymentRejected: () => import('../PaymentResponses/PaymentRejected.vue')
	},
	data() {
		return {
			loading: false,
			component: null,
			response: {},
			addressInfo: []
		};
	},
	props: {
		error: { type: String, default: null },
		amount: { type: Number, required: true },
		btcAmount: { type: String, required: true },
		currency: { type: String, required: true },
		address: { type: String, required: true },
		addressSeg: { type: String, required: true },
		qrCode: { type: String, required: true },
		qrCodeSeg: { type: String, required: true },
		transactionId: { type: String, required: true },
		packageName: { type: String, default: '' },
		staticInfo: { type: String, default: '' }
	},
	methods: {
		copyAmount(elementId) {
			const range = document.createRange();
			range.selectNode(document.getElementById(elementId));
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);

			document.execCommand('copy');
			window.getSelection().removeAllRanges();
		},
		copyHandler(refName) {
			const element = this.$refs[refName][0].$el.querySelector('input');
			element.select();
			document.execCommand('copy');
			this.snackbar = true;
		}
	},
	async created() {
		if (this.error) {
			return;
		}

		this.addressInfo = [
			{ address: this.addressSeg, qrCode: this.qrCodeSeg, type: 'Segwit' },
			{ address: this.address, qrCode: this.qrCode, type: 'Legacy' }
		];

		const interval = setInterval(async () => {
			const txDetails = await ApiService.publicApi.getTransactionStatus(this.transactionId);
			if (txDetails.status === 'Pending') {
				clearInterval(interval);
				this.response.msg = txDetails.note;
				this.component = 'PaymentRejected';
			}
			if (txDetails.status === 'Approved') {
				clearInterval(interval);
				this.component = 'PaymentProcessed';
			}
		}, 5000);
	}
};
</script>
