<template>
	<iframe v-if="response" :src="response"></iframe>
</template>

<script>
import ApiService from '../../services/ApiService.js';
export default {
	data() {
		return {
			response: ''
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true }
	},
	methods: {
		async setUrlResponse() {
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId);
			this.response = response;
		}
	},
	created() {
		this.setUrlResponse();
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
</style>
