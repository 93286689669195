<template>
	<v-container>
		<div class="text-h3">{{ $t('global.paymentProcessed') }}</div>
		<v-avatar color="#f8faf5" size="200">
			<v-icon size="100" color="green darken-2">mdi-check</v-icon>
		</v-avatar>
		<div class="body-1">{{ $t('global.paymentProcessedDetails') }}</div>
		<v-row v-if="response.cardDescription">
			<v-col>
				<p :class="$style.cardDescriptionStyle">
					{{ `${$t('global.importantCardDescriptor')} "${response.cardDescription}".` }}
				</p>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {};
	},
	props: {
		response: { type: Object, required: true }
	}
};
</script>

<style module>
.cardDescriptionStyle {
	font-size: 20px;
	color: red;
}
</style>
