<!-- We need to change this in the next sprint -->
<template>
	<v-container>
		<div v-if="!txId">
			<div class="text-h3">{{ $t('global.paymentPending') }}</div>
			<v-avatar color="#f8faf5" size="200">
				<v-icon size="100" color="green darken-2">mdi-timer-sand</v-icon>
			</v-avatar>
			<div class="body-1">{{ $t('global.paymentPendingDetails') }}</div>
		</div>

		<template v-if="!status && txId">
			<div class="text-h3">{{ $t('global.pleaseWaitWhileWeCheck') }}</div>
			<v-avatar color="#f8faf5" size="200">
				<v-icon size="100" color="green darken-2">mdi-timer-sand</v-icon>
			</v-avatar>
			<div class="body-1">{{ $t('global.dontCloseOrGoBack') }}</div>
		</template>

		<template v-if="status === 'Rejected'">
			<div class="text-h3">{{ $t('global.paymentRejected') }}</div>
			<v-avatar color="#f8faf5" size="200">
				<v-icon size="100" color="red darken-2">mdi-close</v-icon>
			</v-avatar>
			<div class="body-1">{{ $t('global.paymentRejectedDetails') }}</div>
			<div style="font-size: 20px !important;font-weight: 800;">{{ status }} - {{ reason }}</div>
		</template>

		<template v-if="status === 'Approved'">
			<div class="text-h3">{{ $t('global.paymentProcessed') }}</div>
			<v-avatar color="#f8faf5" size="200">
				<v-icon size="100" color="green darken-2">mdi-check</v-icon>
			</v-avatar>
			<div class="body-1">{{ $t('global.paymentProcessedDetails') }}</div>
			<v-row v-if="cardDescription">
				<v-col>
					<p :class="$style.cardDescriptionStyle">
						{{ `${$t('global.importantCardDescriptor')} "${cardDescription}".` }}
					</p>
				</v-col>
			</v-row>
		</template>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			txId: null,
			status: null,
			reason: null,
			cardDescription: null
		};
	},
	created() {
		const queryStringParams = Object.fromEntries(new URLSearchParams(window.location.search));

		if (queryStringParams.id) {
			this.txId = queryStringParams.id;

			const interval = setInterval(async () => {
				const txDetails = await ApiService.publicApi.getTransactionStatus(this.txId);
				this.status = txDetails.status === 'Initial' ? null : txDetails.status;
				this.reason = txDetails.note;
				this.cardDescription = txDetails.cardDescription;

				if (this.status) {
					clearInterval(interval);
				}
			}, 5000);
		}
	}
};
</script>

<style module>
.cardDescriptionStyle {
	font-size: 20px;
	color: red;
}
</style>
