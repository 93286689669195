<template>
	<v-container>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-dialog v-model="showMissingParamsModal" :hide-overlay="true" persistent max-width="500">
			<v-container>
				<v-form v-model="isFormValid">
					<v-row :class="$style.titleWrapper">
						<v-col class="white--text text-h5">
							{{ $t('global.fillMissingInfo') }}
						</v-col>
					</v-row>
					<v-row v-for="missingField in missingParams" :key="missingField">
						<v-col>
							<input-field v-model="missingInfoClientInput[missingField]" required :label="missingField" type="special-text"></input-field>
						</v-col>
					</v-row>
					<v-row justify="space-around" class="mt-5">
						<input-field
							required
							type="boolean"
							v-model="agree"
							:label="
								paymentPageDetails.mSite
									? `By checking the box and clicking continue, you confirm that you acknowledge the terms presented in the
										 Terms of Services
										 and Privacy Policy and you also certify
					that you are 18 years of age or older. Today's 
					charge will be ${paymentPageDetails.amount} ${paymentPageDetails.currency} for 
					${paymentPageDetails.packageName}. Be aware that this is a ONE time charge. In order to renew your package, 
					you will need to do this process again.`
									: `${$t('global.paymentDiscloser')} ${paymentPageDetails.amount} ${paymentPageDetails.currency}`
							"
							:props="{ styleType: 'outlined', autocomplete: 'off' }"
						/>
					</v-row>
					<v-row>
						<v-col>
							<v-btn class="login-button" :disabled="!isFormValid || !agree" @click="submit">
								{{ $t('global.submit') }}
							</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</v-container>
		</v-dialog>
		<v-row :class="$style.titleWrapper">
			<v-btn icon large :class="$style.cancelBtn" @click="goBack"> <v-icon>mdi-arrow-left</v-icon></v-btn>
			<v-col class="white--text text-h5">
				{{ $t('home.selectMethodTitle') }}
			</v-col>
			<v-btn icon large :class="$style.giftBtn" @click="togglePromoCodeDialog"> <v-icon>mdi-plus-circle-outline</v-icon></v-btn>
		</v-row>
		<v-row justify="center" style="background-color: ghostwhite">
			<v-col v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" cols="3">
				<v-container :class="$style.container">
					<v-card :loading="choosingPaymentMethod === paymentMethod.id" :disabled="!!choosingPaymentMethod" outlined @click="choosePaymentMethod(paymentMethod.id)">
						<v-img :src="paymentMethod.image" />
						<v-divider />
						<span class="text-caption">{{ paymentMethod.name }}</span>
					</v-card>
				</v-container>
			</v-col>
		</v-row>

		<v-row :class="$style.titleWrapper">
			<v-col class="white--text text-caption">
				{{ $t('home.copyright') }}
			</v-col>
		</v-row>
		<payments-page v-if="finalPaymentPageDetails" :payment-page-details="finalPaymentPageDetails" @cancel-payment-method="onPaymentMethodCanceled" />
		<v-dialog :value="toShowPromoCodeDialog" persistent max-width="500">
			<v-card style="background-color: ghostwhite">
				<v-card-title class="pa-0 ma-0 justify-end">
					<v-btn icon large class="mr-2" @click="togglePromoCodeDialog"><v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>
				<v-divider class="mb-3" />
				<v-card-text>
					<span :class="$style.promoCodeTitle">{{ $t('global.applyPromoCode') }}</span>
					<input-field v-model="promoCode" required label="code" type="special-text" :props="{ styleType: 'outlined' }"></input-field>
					<v-btn class="mt-5" @click="promoCodeHandler" :disabled="promoCode.length === 0 || loading">{{ $t('global.apply') }}</v-btn>
					<span v-if="promoCodeErr" :class="$style.promoCodeTitle">{{ $t(`global.${promoCodeErr}`) }}</span>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import ApiService from '../services/ApiService.js';

export default {
	components: { PaymentsPage: () => import('./PaymentsPage.vue') },
	props: {
		transactionId: { type: String, required: true },
		paymentMethods: { type: Array, required: true },
		promo: { type: Object, default: () => {} }
	},
	data() {
		return {
			choosingPaymentMethod: false,
			paymentPageDetails: {},
			finalPaymentPageDetails: null,
			missingParams: null,
			showMissingParamsModal: false,
			missingInfoClientInput: {},
			isFormValid: false,
			agree: false,
			toShowPromoCodeDialog: false,
			promoCode: '',
			loading: false,
			promoCodeErr: null
		};
	},
	methods: {
		togglePromoCodeDialog() {
			this.toShowPromoCodeDialog = !this.toShowPromoCodeDialog;
		},
		goBack() {
			window.location.href = document.referrer;
		},
		async promoCodeHandler() {
			this.loading = true;
			this.promoCodeErr = null;
			const res = await ApiService.publicApi.applyPromoCode(this.transactionId, this.promoCode);
			this.promoCodeErr = _.get(res, 'error');
			this.loading = false;
			if (!this.promoCodeErr) {
				this.toShowPromoCodeDialog = false;
			}
		},
		async choosePaymentMethod(paymentMethodId) {
			try {
				this.choosingPaymentMethod = true;
				const clientIp = await Promise.race([ApiService.publicApi.getCurrentClientIpIpify(), ApiService.publicApi.getCurrentClientIpCloudflare()]);

				this.paymentPageDetails = await ApiService.publicApi.selectPaymentMethod(this.transactionId, paymentMethodId, clientIp);

				if (!this.paymentPageDetails.missingParamsObj || (this.paymentPageDetails.missingParamsObj && (this.paymentPageDetails.missingParamsObj.mode === 'creditCardDefault' || _.size(this.paymentPageDetails.missingParamsObj.params) === 0))) {
					this.finalPaymentPageDetails = this.paymentPageDetails;
				} else {
					const { missingParamsObj } = this.paymentPageDetails;
					if (missingParamsObj.mode !== 'creditCardDefault') {
						this.missingParams = missingParamsObj.params;
						this.showMissingParamsModal = true;
					}
				}
			} catch (error) {
				console.log(error);

				this.choosingPaymentMethod = false;
				window.location.reload();
			}
			this.choosingPaymentMethod = false;
		},
		onPaymentMethodCanceled() {
			window.location.reload();
		},
		async submit() {
			this.finalPaymentPageDetails = { missingInfoClientInput: this.missingInfoClientInput, ...this.paymentPageDetails };
			this.showMissingParamsModal = false;
		}
	},
	async created() {
		if (this.promo.name) {
			this.promoCode = this.promo.name;
		}
		const { payment } = this.$route.query;

		if (payment !== undefined) {
			const selectedPayment = this.paymentMethods.filter((paymentMethod) => paymentMethod.name.toLowerCase().includes(payment.toLowerCase()));
			if (selectedPayment?.[0]) {
				this.choosePaymentMethod(Number(selectedPayment[0].id));
			}
		}
	}
};
</script>

<style module>
.container {
	max-width: 200px;
	min-width: 100px;
}

.titleWrapper {
	background-image: linear-gradient(135deg, rgb(54, 3, 132), rgb(218, 11, 104)) !important;
	position: relative;
}

.cancelBtn {
	color: #fff !important;
	margin: auto 10px;
}

.giftBtn {
	color: #fff !important;
	position: absolute;
	right: 10px;
	top: 5px;
}

.promoCodeTitle {
	font-size: 18px;
	font-weight: bold;
	text-decoration: underline;
	display: block;
	margin-bottom: 16px;
}
</style>
