<template>
	<v-app :class="$style.app">
		<v-main>
			<router-view />
		</v-main>
		<message-container />
	</v-app>
</template>

<script>
export default {};
</script>

<style module>
.app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
</style>
