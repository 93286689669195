import _ from 'lodash';
import VueRouter from 'vue-router';

const buildRoutes = () => {
	const routes = [];
	const viewsContext = require.context('@/views', true, /index.vue$/);
	_.each(viewsContext.keys(), (key) => {
		let path = key.replace(/(\.|\/index\.vue)/g, '');
		if (path === '/Home') {
			path = '/';
		}
		routes.push({ path, component: viewsContext(key).default });
	});
	routes.push({ path: '*', redirect: '/' });
	return routes;
};

const createRouter = () => {
	const router = new VueRouter({ mode: 'history', base: '/', routes: buildRoutes() });
	return router;
};

export { createRouter };
