import Vuex from 'vuex';

const createStore = () => {
	return new Vuex.Store({
		state: {
			isLoading: false,
			isDarkMode: false,
			language: 'en'
		},
		mutations: {
			setIsLoading(state, isLoading) {
				state.isLoading = isLoading ?? state.isLoading;
			},
			setDarkMode(state, isDarkMode) {
				state.isDarkMode = isDarkMode ?? state.isDarkMode;
			},
			setLanguage(state, language) {
				state.language = language ?? state.language;
			}
		},
		actions: {
			changeIsLoading: ({ commit }, isLoading) => commit('setIsLoading', isLoading),
			toggleDarkMode: ({ state, commit }) => commit('setDarkMode', !state.isDarkMode),
			changeLanguage: ({ commit }, language) => commit('setLanguage', language)
		},
		getters: {}
	});
};

export { createStore };
