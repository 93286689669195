<template>
	<div ref="paypal" />
</template>

<script>
export default {
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		walletId: { type: String, required: true }
	},
	methods: {
		setLoaded() {
			window.paypal
				.Buttons({
					createOrder: (data, actions) => {
						return actions.order.create({
							purchase_units: [{ custom_id: this.transactionId, amount: { value: this.amount.toString(), currency_code: this.currency } }]
						});
					},
					onApprove: async (data) => {
						this.$emit('payment-processed', data);
					},
					onError: (error) => {
						this.$emit('payment-error', error);
					}
				})
				.render(this.$refs.paypal);
		},
		loadPaypalPaymentForm() {
			const script = document.createElement('script');
			script.src = `https://www.paypal.com/sdk/js?client-id=${this.walletId}&currency=${this.currency}`;
			script.addEventListener('load', this.setLoaded);
			document.body.appendChild(script);
		}
	},
	mounted() {
		this.loadPaypalPaymentForm();
	}
};
</script>
