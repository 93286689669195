<template>
	<div :class="$style.stripeForm">
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>

		<v-card-title elevation="2">{{ packageName }} ({{ amount }} {{ currency }})</v-card-title>
		<v-divider></v-divider>

		<form @submit.prevent="onSubmit()">
			<fieldset>
				<legend :class="$style.cardOnly">{{ $t('global.payWithCard') }}</legend>
				<div :class="$style.container">
					<div :id="$style.card" ref="card"></div>
					<button type="submit">{{ $t('global.submit') }}</button>
				</div>
			</fieldset>
			<div :class="$style.errorCard" ref="errorCard" role="alert">
				<v-icon color="#e25950">mdi-alert-circle</v-icon>
				<span class="message"></span>
			</div>
		</form>
		<v-row v-if="cardDescription">
			<v-col>
				<p :class="$style.cardDescriptionStyle">
					{{ `${$t('global.importantCardDescriptor')} "${cardDescription}".` }}
				</p>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			clientSecret: null,
			stripe: null,
			elements: null,
			card: null,
			loading: false
		};
	},
	props: {
		packageName: { type: String, default: '' },
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transaction: { type: Object, required: true },
		countryCode: { type: String, required: true },
		publicKey: { type: String, required: true },
		missingInfoClientInput: { type: Object, default: () => {} },
		cardDescription: { type: String, default: '' }
	},
	methods: {
		async init() {
			const response = await ApiService.publicApi.createPaymentRequest(this.transaction.id, { missingInfoClientInput: this.missingInfoClientInput });
			if (response && response.client_secret) {
				this.clientSecret = response.client_secret;
				this.stripe = window.Stripe(this.publicKey);
				this.elements = this.stripe.elements();
				const style = {
					base: {
						color: '#32325D',
						fontWeight: 500,
						fontFamily: 'Inter, Open Sans, Segoe UI, sans-serif',
						fontSize: '16px',
						fontSmoothing: 'antialiased',

						'::placeholder': {
							color: '#CFD7DF'
						}
					},
					invalid: {
						color: '#E25950'
					}
				};
				this.card = this.elements.create('card', { style });
				this.card.mount(`#${this.$style.card}`);
				this.register(this.card);
				return;
			}
			this.handleDisplayErrorMessage(response.raw.message);
		},
		loadStripePaymentForm() {
			const script = document.createElement('script');
			script.src = 'https://js.stripe.com/v3/';
			document.body.appendChild(script);
		},
		register(element) {
			const { errorCard } = this.$refs;
			const errorCardMessage = errorCard.querySelector('.message');
			element.on('change', (event) => {
				if (event.error) {
					errorCard.classList.add(this.$style.visible);
					errorCardMessage.innerText = event.error.message;
				} else {
					errorCard.classList.remove(this.$style.visible);
					errorCardMessage.innerText = null;
				}
			});
		},
		async onSubmit() {
			try {
				this.loading = true;
				const result = await this.stripe.confirmCardPayment(this.clientSecret, {
					payment_method: {
						card: this.card,
						billing_details: {
							name: `${this.transaction.firstName} ${this.transaction.lastName}`,
							email: this.transaction.email,
							phone: this.transaction.phone
						}
					}
				});
				this.loading = false;
				if (result.paymentIntent) {
					this.$emit('payment-processed', { response: result.paymentIntent, cardDescription: this.cardDescription });
					return;
				}
				if (result.error) {
					this.handleDisplayErrorMessage(result.error.message);
					this.$emit('payment-error', result.error);
					return;
				}
			} catch (error) {
				this.loading = false;
			}
		},
		handleDisplayErrorMessage(message) {
			const { errorCard } = this.$refs;
			const errorCardMessage = errorCard.querySelector('.message');
			errorCard.classList.add(this.$style.visible);
			errorCardMessage.innerText = message;
		}
	},
	created() {
		this.loadStripePaymentForm();
		this.init();
	}
};
</script>

<style module>
.stripeForm * {
	font-family: Inter, Open Sans, Segoe UI, sans-serif;
	font-size: 16px;
	font-weight: 500;
}

.stripeForm form {
	max-width: 496px;
	padding: 0 15px;
	margin: 0 auto;
}

.stripeForm form > * + * {
	margin-top: 20px;
}

.stripeForm .container {
	background-color: #fff;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	padding: 3px;
}

.stripeForm fieldset {
	border-style: none;
	padding: 5px;
	margin-left: -5px;
	margin-right: -5px;
	background: rgba(18, 91, 152, 0.05);
	border-radius: 8px;
}

.stripeForm fieldset legend {
	float: left;
	width: 100%;
	text-align: center;
	font-size: 13px;
	color: #8898aa;
	padding: 3px 10px 7px;
}

.stripeForm .cardOnly {
	display: block;
}

.stripeForm fieldset legend + * {
	clear: both;
}

.stripeForm input,
.stripeForm button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	border-style: none;
	color: #fff;
}

.stripeForm input:-webkit-autofill {
	transition: background-color 100000000s;
}

.stripeForm #card {
	padding: 10px;
	margin-bottom: 2px;
}

.stripeForm input::-webkit-input-placeholder {
	color: #9bacc8;
}

.stripeForm input::-moz-placeholder {
	color: #9bacc8;
}

.stripeForm input:-ms-input-placeholder {
	color: #9bacc8;
}

.stripeForm button {
	display: block;
	width: 100%;
	height: 37px;
	background-color: #1976d2;
	border-radius: 2px;
	color: #fff;
	cursor: pointer;
}

.stripeForm button:active {
	background-color: #1976d2;
}

.stripeForm .errorCard .message {
	color: #e25950;
}

.errorCard {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;
	padding: 0 15px;
	font-size: 13px;
	opacity: 0;
	transform: translateY(10px);
	transition-property: opacity, transform;
	transition-duration: 0.35s;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.errorCard.visible {
	opacity: 1;
	transform: none;
}

.cardDescriptionStyle {
	font-size: 20px;
	color: red;
}
</style>
