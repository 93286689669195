<template>
	<div>
		<v-card-title elevation="2">{{ $t('global.subscription') }}: {{ packageName }} ({{ amount }} {{ currency }})</v-card-title>
		<v-divider></v-divider>
		<p>
			<b style="font-size:20px">{{ $t('global.pleaseNote') }}</b> {{ $t('global.cryptoFeeNotice') }}
		</p>
		<iframe
			:src="frameUrl"
			style="border-radius:15px;border:1px solid #eee;padding:3px 6px;margin:10px;"
			scrolling="no"
			marginheight="0"
			marginwidth="0"
			frameborder="0"
			width="530"
			height="230"
		></iframe>
	</div>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			frameUrl: null,
			loading: false
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		boxId: { type: Number, required: true },
		transactionId: { type: String, required: true },
		orderId: { type: String, required: true },
		publicKey: { type: String, required: true },
		packageName: { type: String, default: '' }
	},
	methods: {
		async cryptobox_show(boxID, coinName, public_key, amount, amountUSD, period, language, iframeID, userID, userFormat, orderID, ver, width, height) {
			if (typeof width !== 'number') width = 0;
			if (typeof height !== 'number') height = 0;

			const paymentResponse = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				coinName,
				amount,
				amountUSD,
				period,
				language,
				orderID,
				userID,
				userFormat,
				iframeID,
				width,
				height
			});

			const { md5 } = paymentResponse;

			const url = `https://coins.gourl.io/b/${encodeURIComponent(boxID)}/c/${encodeURIComponent(coinName)}/p/${encodeURIComponent(public_key)}/a/${encodeURIComponent(
				amount
			)}/au/${encodeURIComponent(amountUSD)}/pe/${encodeURIComponent(period.replace(' ', '_'))}/l/${encodeURIComponent(language)}/i/${encodeURIComponent(
				iframeID
			)}/u/${encodeURIComponent(userID)}/us/${encodeURIComponent(userFormat)}/o/${encodeURIComponent(orderID)}/t/${encodeURIComponent(ver)}${
				width > 0 ? `/ws/${encodeURIComponent(width)}` : ''
			}${height > 0 ? `/hs/${encodeURIComponent(height)}` : ''}/h/${encodeURIComponent(md5)}/z/${Math.random()}`;

			this.frameUrl = url;
		}
	},
	async created() {
		await this.cryptobox_show(this.boxId, 'Bitcoin', this.publicKey, 0, this.amount, '60 MINUTE', 'en', 'box1913', 'user4', 'MANUAL', this.orderId, '', 530, 230);
	}
};
</script>
